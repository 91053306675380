<template>
  <div class="page-login d-flex flex-column justify-content-center align-items-center vh-100">
    <img
    v-if="constantes.EMPRESA === 'veloz'"
      :src="require('../../../assets/images/veloz-logo-login.png')"
      alt=""
      class="img-fluid mb-3"
      style="max-width: 15%;"
    >
    <img
    v-if="constantes.EMPRESA === 'uniavan'"
      :src="require('../../../assets/images/seu-logo-login.png')"
      alt=""
      class="img-fluid mb-3"
    >
    <div class="block__login bg-oct">
      <h1 class="color-sec fs-24 mb-4 font-normal">
        Login
      </h1>
      <p class="mb-4 fs-14">
        Coloque seus dados para entrar.
      </p>

      <div
        v-if="show_error"
        class="alert alert-danger"
        role="alert"
      >
        {{ message_error }}
      </div>

      <form
        class="block__form"
        @submit.prevent="submit()"
      >
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            id="email"
            v-model="form.username"
            type="email"
            class="form-control"
            required
          >
        </div>

        <div class="form-group mb-4">
          <label for="password">Senha</label>
          <input
            id="password"
            v-model="form.password"
            type="password"
            class="form-control"
            required
          >
        </div>

        <div class="row mb-3">
          <div class="col-auto">
            <div class="form-group custom-control custom-checkbox">
              <input
                id="remember"
                type="checkbox"
                class="custom-control-input"
              >
              <label
                class="custom-control-label"
                for="remember"
              >Lembrar de mim</label>
            </div>
          </div>

          <div class="col text-right">
            <button
              type="submit"
              class="btn btn-classic"
            >
              Entrar
            </button>
          </div>
        </div>
      </form>

      <a
        href="#"
        class="link link-reset"
      >Esqueci minha senha</a>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import Swal from "sweetalert2";
import {constantes} from "@/constants";
import authService from "@/modules/auth/services";

export default {
  data: () => ({
    form: {
      username: '',
      password: ''
    },
    show_error: false,
    message_error: null
  }),
  created() {
    authService.logout();
  },
  computed: {
    // Deixando a variável constantes disponível no escopo do template
    constantes: {
      get () {
        return constantes
      }
    }
  },
  methods: {
    ...mapActions('auth', ['ActionDoLogin']),
    async submit() {
      try {
        this.$root.$emit('Spinner::show');

        var result = await this.ActionDoLogin(this.form);
        const erroCredenciaisInvalidas = () => {
          this.$root.$emit('Spinner::hide');
          Swal.fire({
            position: "center",
            icon: 'error',
            title: 'Acesso negado. Credenciais inválidas!',
            showConfirmButton: false,
            timer: 1500
          });
        }
        if(!result){
          erroCredenciaisInvalidas();
        } else {
          this.$api
              .get('users/info')
              .then((response) => {
                if(response.data.user.tipoUsuario === 'TIPO_USUARIO_ALUNO'){
                  erroCredenciaisInvalidas();
                  return false;
                }
                console.log(response)
                localStorage.setItem(constantes.STORAGE_UNIAVAN_USER_ROLES, response.data.user.roles)
                localStorage.setItem(constantes.STORAGE_UNIAVAN_TIPO_USUARIO, response.data.user.tipoUsuario)
                localStorage.setItem(constantes.STORAGE_UNIAVAN_NOME_USUARIO, response.data.nomeUser)
                localStorage.setItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO, response.data.instituicao.id)
                localStorage.setItem(constantes.STORAGE_UNIAVAN_NOME_INSTITUICAO, response.data.instituicao.razaoSocial)

                this.$root.$emit('Spinner::hide');
                this.$router.push({'name':'dashboard'});

              }).catch(() => {
              });
        }
      } catch (err) {
        this.show_error = true;
        this.message_error = err.body.message
        this.form.password = ''

        setTimeout(()=>{
          this.message_error = ''
          this.show_error = false
        },3000)
      }
    }
  }
}

</script>
