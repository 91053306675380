<template>
  <div class="modal fade" id="agenda" tabindex="-1" aria-labelledby="agendaLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="agendaLabel">Agenda - Editar</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img src="assets/images/close.svg"></span>
        </button>
      </div>
      <div class="modal-body p-0 pb-5">
        <form class="block__form block__form--schedule has-tooltip">
          <div class="form-header">
            <div class="form-group full-select">
              <label for="disciplina1">Componente Curricular <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
              <select class="select-simple" name="disciplina1" id="disciplina1">
                <option value="1">Matemática</option>
                <option value="2">Português</option>
                <option value="3">Geografia</option>
              </select>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group full-select">
                  <label for="ano1">Ano <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
                  <select class="select-simple" name="ano1" id="ano1">
                    <option value="1">5 Ano</option>
                    <option value="2">6 Ano</option>
                    <option value="3">7 Ano</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group full-select">
                  <label for="turma1">Turma <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
                  <select class="select-simple" name="turma1" id="turma1">
                    <option value="1">A</option>
                    <option value="2">B</option>
                    <option value="3">C</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4"></div>
            </div>
          </div>

          <div class="form-body">
            <div class="form-group">
              <label for="tituloProva">Título da Prova <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
              <input type="text" class="form-control" id="tituloProva" value="1 Bimestre">
            </div>
          </div>

          <div class="form-footer d-flex">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group input-group input-group__date-time">
                  <label class="w-100" for="date">Data e hora de início <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                  </div>
                  <input type="text" class="form-control" id="date" value="01/12/2020">
                  <input type="text" class="form-control" id="time" value="10:00">
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row align-items-end">
                  <label class="col-12" for="tempo">Tempo de Execução <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
                  <div class="col-3">
                    <input type="text" class="form-control" id="tempo">
                  </div>
                  <div class="col-9 pl-0">
                    <p class="m-0 color-pri">Minutos</p>
                  </div>
                </div>
              </div>

              <div class="col-12 text-right">
                <button type="submit" class="btn btn-classic ml-auto">Salvar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal fade" id="agendaEditarProva" tabindex="-1" aria-labelledby="agendaEditarProvaLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="agendaEditarProvaLabel">Agenda - Prova (Status)</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img src="assets/images/close.svg"></span>
        </button>
      </div>
      <div class="modal-body p-0 pb-5">
        <form class="block__form block__form--schedule has-tooltip">
          <div class="form-header">
            <div class="form-group full-select">
              <label for="disciplina">Disciplina <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
              <select class="select-simple" name="disciplina" id="disciplina">
                <option value="1">Matemática</option>
                <option value="2">Português</option>
                <option value="3">Geografia</option>
              </select>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group full-select">
                  <label for="ano">Ano <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
                  <select class="select-simple" name="ano" id="ano">
                    <option value="1">5 Ano</option>
                    <option value="2">6 Ano</option>
                    <option value="3">7 Ano</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group full-select">
                  <label for="turma">Turma <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
                  <select class="select-simple" name="turma" id="turma">
                    <option value="1">A</option>
                    <option value="2">B</option>
                    <option value="3">C</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4"></div>
            </div>
          </div>

          <div class="form-body">
            <div class="form-group">
              <label for="tituloProva">Título da Prova <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
              <input type="text" class="form-control" id="tituloProva" value="1 Bimestre">
            </div>
          </div>

          <div class="form-footer d-flex">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group input-group input-group__date-time">
                  <label class="w-100" for="date">Data e hora de início <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                  </div>
                  <input type="text" class="form-control" id="date" value="01/12/2020">
                  <input type="text" class="form-control" id="time" value="10:00">
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group row align-items-end">
                  <label class="col-12" for="tempo">Tempo de Execução <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"></i></label>
                  <div class="col-3">
                    <input type="number" class="form-control" id="tempo">
                  </div>
                  <div class="col-9 pl-0">
                    <p class="m-0 color-pri">Minutos</p>
                  </div>
                </div>
              </div>

              <div class="col-12 text-right">
                <button type="submit" class="btn btn-classic ml-auto">Botão de ação de acordo com o STATUS</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  components: {

  },
  data() {
    return {
      showLeftMenu: true,
      provas: [],
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
      },
    };
  },
  created() {
   
  },
  methods: {
    
  },
};
</script>

<style>
</style>