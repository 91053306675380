<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content bg-oct" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-5 pt-4"
          >
            <h1 class="color-clean">Meu Perfil</h1>

            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  Meu Perfil
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="row">
              <div class="col-md-5">
                <form class="block__form"
                  @submit.prevent="salvar()">
                  <div class="form-group">
                    <label for="email">E-mail</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      v-model="user.email"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="password">Senha</label>
                    <input
                      type="password"
                      class="form-control"
                      value=""
                      v-model="user.password"
                      autocomplete="new-password"
                    />
                  </div>
                  <div class="form-group">
                    <label for="name">Nome</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      v-model="user.nome"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="phone">Telefone</label>
                    <input
                      type="tel"
                      class="form-control"
                      id="phone"
                      v-model="user.telefone"
                    />
                  </div>
                  <!-- <div class="form-group mb-4">
                    <label for="photo">Minha foto</label>
                    <input type="file" class="form-control-file" id="photo" />
                  </div> -->

                  <button
                    type="submit"
                    class="btn btn-classic"
                  >
                    Salvar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import {constantes} from "@/constants";
import Swal from "sweetalert2";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
  },
  data() {
    return {
      showAgendarModal: false,
      showLeftMenu: true,
      user: [],
      infosUser: [],
    };
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
  },
  methods: {
    loadData() {
      this.$api
        .get("users/info")
        .then((response) => {
          this.user = response.data.user;
          this.user.nome = response.data.nomeUser;
          this.user.telefone = response.data.telefoneUser;
          this.user.password = '';
          console.log(this.user);
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    loadProfessor(id) {
      console.log(id)
      this.$api
        .get("professors")
        .then((response) => {
          // this.user = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    salvar() {
      this.$api
        .put("users/update", this.user)
        .then((response) => {
          localStorage.setItem(constantes.STORAGE_UNIAVAN_USER_ROLES, response.data.user.roles)
          localStorage.setItem(constantes.STORAGE_UNIAVAN_TIPO_USUARIO, response.data.user.tipoUsuario)
          localStorage.setItem(constantes.STORAGE_UNIAVAN_NOME_USUARIO, response.data.nomeUser)
          localStorage.setItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO, response.data.instituicao.id)
          localStorage.setItem(constantes.STORAGE_UNIAVAN_NOME_INSTITUICAO, response.data.instituicao.razaoSocial)
          this.$root.$emit("Spinner::hide");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Dados atualizados com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });

          setTimeout(function () {
            location.reload();
          }, 1000);
        }
        , (err, t) => {
          console.log(err)
          const title = err && err.response && err.response.data ? err.response.data.detail : "Ocorreu um erro.";
          Swal.fire({
            position: "center",
            icon: "error",
            title: title,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        )
    },
  },
};
</script>

<style></style>
